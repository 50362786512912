import React from 'react';
import 'moment/locale/es';
import { Modal, Button } from 'antd';
import moment from 'moment';
import ContentImage from '../common/ContentImage.jsx';

const EventModal = ({isModalVisible,  handleCancel, modalContent}) => {    

    const hora = moment(modalContent.eventDate,'YYYY-MM-DD HH:mm:ss').format('HH:mm') !== '00:00' && moment(modalContent.eventDate,'YYYY-MM-DD HH:mm:ss').format('HH:mm A')

    return (
	<Modal className="eventModal" visible={isModalVisible} onCancel={handleCancel}>
		<div className="mobdata">
			<div className="row">
				<div className="col-12">
					<h2 className="eventTitle">{modalContent?.title}</h2>
					<div className="eventDate">
						<h5>{moment(modalContent.eventDate,'YYYY-MM-DD').format('DD MMMM YY')}</h5>
						<p>{moment(modalContent.eventDate,'YYYY-MM-DD HH:mm:ss').format('HH:MM')}</p>
					</div>
				</div>
				<div className="col-12">
					<div className="eventDetail" dangerouslySetInnerHTML={{ __html:modalContent?.text}}></div>
				</div>
				<div className="col-12">
					<div className="row cta">
					{
						modalContent.button1Text !== ""
						?
						<div className="col-6">
						<button type="button" className="eventCTA"> <a href={modalContent.button1Link}>{modalContent.button1Text}</a> </button>
						</div>
						:""
					}
					{
						modalContent.button2Text !== ""
						?
						<div className="col-6">
						<button type="button" className="eventCTA"> <a href={modalContent.button2Link}>{modalContent.button2Text}</a> </button>
						</div>
						:""
					}
					{
						modalContent.button3Text !== ""
						?
						<div className="col-6">
						<button type="button" className="eventCTA"> <a href={modalContent.button3Link}>{modalContent.button3Text}</a> </button>
						</div>
						:""
					}
					</div>
				</div>
				<div className="col-12">
					<div className="eventImg">
					<ContentImage imageName={ modalContent?.image} isImageComponent={false}/>
					</div>
				</div>
			</div>
		</div>
		<div className="hdtabdata">
			<div className="tabdataabove">
				<h2 className="eventTitle">{modalContent?.title}</h2>
				<div className="line4"></div>
			</div>
			<div className="row">
				<div className="col-5">
					<div className="eventImg">
						<ContentImage imageName={ modalContent?.image} isImageComponent={false}/>
					</div>
					<div className="eventDate">
						<h5>{moment(modalContent.eventDate).locale('es').format('DD MMMM YY')}</h5>
						<p>{hora}</p>
					</div>
				</div>
				<div className="col-7">
					<div>
						<h2 className="eventTitle">{modalContent?.title}</h2>
						<div className="line4"></div>
						<div className="eventDetail" dangerouslySetInnerHTML={{ __html:modalContent?.text}}></div>
					</div>
					<div className="row cta">
						{
						modalContent.button1Text !== ""
						?
							<div className="col-4">
							<button type="button" className="eventCTA"> <a href={modalContent.button1Link}>{modalContent.button1Text}</a> </button>
							</div>
						:""
						}
						{
						modalContent.button2Text !== ""
						?
							<div className="col-4">
							<button type="button" className="eventCTA"> <a href={modalContent.button2Link}>{modalContent.button2Text}</a> </button>
							</div>
						:""
						}
						{
						modalContent.button3Text !== ""
						?
							<div className="col-4">
							<button type="button" className="eventCTA"> <a href={modalContent.button3Link}>{modalContent.button3Text}</a> </button>
							</div>
						:""
						}
					</div>
				</div>
			</div>
			<div className="tabdatabelow">
				<div className="row">
					{
						modalContent.button1Text !== ""
						?
						<div className="col-4">
						<button type="button" className="eventCTA previa"> <a href={modalContent.button1Link}>{modalContent.button1Text}</a> </button>
						</div>
						:""
					}
					{
						modalContent.button2Text !== ""
						?
						<div className="col-4">
						<button type="button" className="eventCTA ficha"> <a href={modalContent.button2Link}>{modalContent.button2Text}</a> </button>
						</div>
						:""
					}
					{
						modalContent.button3Text !== ""
						?
						<div className="col-4">
						<button type="button" className="eventCTA hill"> <a href={modalContent.button3Link}>{modalContent.button3Text}</a> </button>
						</div>
						:""
					}                
				</div>
			</div>
		</div>
	</Modal>
    )
}

export default EventModal;
